import './About.css'

export default function About() {
    return (
      <div className="home-section">
        <div className="intro-text">
          <h1> Hello! </h1>
          <p> My name is Oscar Lara and I have a B.S. in Computer 
            Science with a concentration in Software Engineering. </p>
          <div className="green-container">
            <div className="indicator-active"> </div>
            <h6 className="status-active"> Looking for work in Web and iOS Development.</h6>
          </div>
        </div>
        <img className='profile' src="./img/sunflower-valentine-round.png" alt="pic" />
        <div className="circle"> </div>
      </div>
      )
    }
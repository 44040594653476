import './App.css';
import Navbar from './components/Navbar';
import About from './components/About'
import ProjectList from './components/ProjectList';
import Contact from './components/Contact';
import HobbiesList from './components/HobbiesList';

function App() {
  return (
    <main id='home'>
    <Navbar />
    <section className='about' id='about'>
        <h1>about</h1>
        <About />
        <div className='curve'> </div>
    </section>
    <div className='spacer layer1'></div>
    <section className='projects' id='projects'>
        <h1>projects</h1>
        <ProjectList />
    </section>
    <div className="spacer layer2"></div>
    <section className='contact' id='contact'>
        <h1>contact</h1>
        <Contact />
        <HobbiesList />
    </section>
    </main>
  );
}

export default App;

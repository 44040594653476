import { hobbies } from "../hobbyData"
import './HobbiesList.css'

export default function HobbiesList() {
    return (
        <div className='hobby-list'>
            <h3> Outside of Work</h3>
                {hobbies.map((hobby) => {
                    return (
                        <div className='glass-panel'>
                            <div key={hobby.id}>
                                <h3> {hobby.type}</h3>
                                <br />
                                <img src={hobby.src} alt="hobby" />
                                <h3> {hobby.title}</h3>
                                <p className="creator"> {hobby.creator}</p>
                            </div>
                        </div>
                    )
                })}
        </div>
    )
}

import { contacts } from "../contactData"
import './Contact.css'

export default function Contact() {
    return (
        <div className='contact-list'>
            <ul>
                {contacts.map((contact) => {
                    return (
                        <div className='glass-panel'>
                            <li key={contact.id}>
                                <a href={contact.link} target="_blank" rel="noopener noreferrer">
                                    <img src={contact.src} alt="contact" />
                                </a>
                                <p> {contact.text}</p>
                            </li>
                        </div>
                    )
                })}
            </ul>
        </div>
    )
}

export const projects = [
    {
        id: 1,
        title:'Realm Subscriptions Tracker',
        src: '/img/subtrackerrealm-mockup_2.png',
        description: 'This app is a subscription tracker with a neumorphic design that tracks the subscriptions of the user. It is made with SwiftUI and Realm.',
        link: 'https://github.com/olara7/SubTrackerRealm'
    },
    {
        id: 2,
        title:'Disney App Home',
        src: '/img/disney-app1_2.png',
        description: 'This website is a clone of the home page from the disney plus website. It is made with ReactJS and the sliders are created with SwiperJS.',
        link: 'https://github.com/olara7/disney-app-home'
    },
    {
        id: 3,
        title:'Job Log',
        src: '/img/joblog-preview-size.png',
        description: 'This website helps you keep track of your job applications. It is made with ReactJS, React-Bootstrap and Firebase.',
        link: 'https://github.com/olara7/job-log'
    },
    {
        id: 4,
        title: 'Blog App',
        src: '/img/blog-app.png',
        description: 'This is a website where you can make posts and view other users post and profiles. It is made with ReactJS, NodeJS, Typescript, PostgresSQL and GraphQL.',
        link: 'http://github.com/olara7/fullstack-client'
    },
    {
        id: 5,
        title: 'Bobs Burger Character Viewer',
        src: '/img/bobsburgerapp-comp.png',
        description: 'SwiftUI App that uses the Bobs Burgers API to get information about the characters Characters information is displayed with a search function and a detail view',
        link: 'https://github.com/olara7/BobsBurgersAPIProject'
    },
    {
        id: 6,
        title: 'Pokedex',
        src: '/img/pokedexProjectHomeGen4.png',
        description: 'View pokemon from each generation with their details and sprites throughout the games. You can also search for a Pokemon. It is build with ReactJS and PokeAPI.',
        link:  'https://github.com/olara7/pokedexproject'
    }
]
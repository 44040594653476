export const hobbies = [
    {
        id: 1,
        type: 'Listening',
        title: 'Felicilandia',
        creator: 'Alvaro Diaz',
        src: '/img/felicilandia.png'
    },
    {
        id: 2,
        type: 'Playing',
        title: 'Smite',
        creator: 'Hi-Rez',
        src: '/img/smite.jpg'
    },
    {
        id: 3,
        type: 'Watching',
        title: 'Attack on Titan',
        creator: 'Hajime Isayama',
        src: '/img/attack-on-titan.jpg'
    }
]
export const links = [
    {
        id: 1,
        text:'about',
        url:'#about'
    },
    {
        id: 2,
        text:'projects',
        url:'#projects'
    },
    {
        id: 3,
        text:'contact',
        url:'#contact'
    }
]
import { projects } from '../projectData'
import './ProjectList.css'

export default function ProjectList() {
    return (
        <div className='project-list'>
            <ul>
                {projects.map((project) => {
                    return (
                        <div className='glass-panel'>
                            <li key={project.id}>
                                <img src={project.src} alt="project" />
                                <h3> {project.title}</h3>
                                <p> {project.description} </p>
                                <br />
                                <a href={project.link} target="_blank" rel="noopener noreferrer">
                                <button className='glass-button'> Github </button>
                                </a>
                            </li>
                        </div>
                    )
                })}
            </ul>
        </div>
    )
}
export const contacts = [
    {
        id: 1,
        text: 'oscar.olara7',
        link: 'https://www.gmail.com',
        src: '/img/gmail-icon.png'
    },
    {
        id: 2,
        text: 'linkedin',
        link: 'https://www.linkedin.com/in/oscarlara7/',
        src: '/img/linkedin-icon.png'
    },
    {
        id: 3,
        text: 'github',
        link: 'https://github.com/olara7',
        src: '/img/github-icon.png'
    }
]
import { links } from '../data'
import './Navbar.css'

export default function Navbar() {
    return (
        <nav className="navbar sticky">
            <div className="nav-center">
                <h1> Oscar Lara</h1>
                <div>
                    {links.map((link) => {
                        return (<a href={link.url} key={link.id}>
                            {link.text}
                        </a>
                        )
                    })}
                </div>
            </div>
        </nav>
    )
}
